.dashboard_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.induction_content {
    margin: 0px 0px 50px 0px;
    width: 900px;
}

.dashboard_content {
    margin: 50px 0px 50px 0px;
    width: 900px;
}

.dashboard_haeder {
    width: 900px;
    color: black;
}

.dashboard_haeder h1 {
    margin: 0px;
    padding: 10px;
    
}

.dashboard_stats {
    margin: 20px 0px;
    width: 900px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.induction_database-content {
    z-index: 5;
    margin: 0px 0px 300px 0px;
    width: 900px;
}

.induction_button {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.induction_language {
    display: flex;
    justify-content: space-between;
}

.induction_button button {
    transition: 0.5s;
    height: 140px;
    width: 280px;
    text-align: left;
    border: none;
    background: rgb(0,61,28);
    border-radius: 10px;
    color: white;
    padding: 10px;
    box-shadow: 0px 10px 15px 0px rgb(0,61,28,0.5);
    cursor: pointer;
}

.induction_button button:hover {
    transition: 0.5s;
    background: rgb(0, 151, 71);
    box-shadow: 0px 10px 15px 0px rgba(0, 148, 69, 0.5);
    transform: scale(1.05)
    
}

.text_induction {
    font-size: 30px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
}

.text_induction2 {
    line-height: 0px;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
}

.dashboard_container h2 {
    margin: 0px;
    font-size: 30px;
    font-family: 'Inter', sans-serif;
}

.dashboard_container h1 {
    font-size: 35px;
    font-family: 'Inter', sans-serif;
}

.database_button button {
    width: 440px;
    height: 140px;
    text-align: left;
    border: none;
    background: rgb(0,61,28);
    box-shadow: 0px 10px 15px 0px rgb(0,61,28,0.5);
    border-radius: 10px;
    color: white;
    padding: 10px;
    transition: 0.5s;
    cursor: pointer;
}

.database_button button:hover {
    transition: 0.5s;
    background: rgb(0, 151, 71);
    box-shadow: 0px 10px 15px 0px rgba(0, 148, 69, 0.5);
    transform: scale(1.05)
    
    
}

.text_database {
    font-size: 30px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
}

.text_database2 {
    line-height: 0px;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
}

.dashboard_stats button {
    width: 440px;
    height: 80px;
    text-align: left;
    border: none;
    background: rgb(0,61,28);
    border-radius: 10px;
    color: white;
    padding: 10px;
    box-shadow: 0px 10px 15px 0px rgb(0,61,28,0.5);
}

.text_stat {
    font-size: 30px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
}

.text_stat2 {
    line-height: 0px;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
}

@media all and (max-width: 1000px) {
    .dashboard_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    
    .induction_content {
        margin: 0px 0px 150px 0px;
        width: 80%;
    }
    
    .dashboard_content {
        margin: 15px 0px 20px 0px;
        width: 80%;
    }
    
    .dashboard_header {
        width: 100%;
        color: black;
    }
    
    .dashboard_header h1 {
        margin: 0px;
        padding: 10px;
        
    }
    
    .dashboard_stats {
        margin: 20px 0px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    
    .induction_database-content {
        z-index: 5;
        margin: 0px 0px 150px 0px;
        width: 80%;
        display: none;
    }
    
    .induction_button {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    
    .induction_language {
        display: flex;
        justify-content: space-between;
    }
    
    .induction_button button {
        transition: 0.5s;
        width: 100%;
        margin-bottom: 20px;
        height: 100px;
        text-align: left;
        border: none;
        background: rgb(0,61,28);
        border-radius: 10px;
        color: white;
        padding: 10px;
        box-shadow: 0px 5px 5px 0px rgb(0,61,28,0.3);
        cursor: pointer;
    }
    
    .induction_button button:hover {
        transition: 0.5s;
        background: rgb(0, 151, 71);
        box-shadow: 0px 5px 5px 0px rgba(0, 148, 69, 0.3);
        transform: scale(1.05)
        
    }
    
    .text_induction {
        font-size: 30px;
        font-weight: 600;
        font-family: 'Inter', sans-serif;
    }
    
    .text_induction2 {
        line-height: 0px;
        font-size: 20px;
        font-family: 'Inter', sans-serif;
    }
    
    .dashboard_container h2 {
        margin: 0px;
        font-size: 30px;
        font-family: 'Inter', sans-serif;
    }
    
    .dashboard_container h1 {
        font-size: 30px;
        font-family: 'Inter', sans-serif;
    }
    
    .database_button button {
        width: 100%;
        height: 100px;
        text-align: left;
        border: none;
        background: rgb(0,61,28);
        box-shadow: 0px 5px 5px 0px rgb(0,61,28,0.3);
        border-radius: 10px;
        color: white;
        padding: 10px;
        transition: 0.5s;
        cursor: pointer;
    }
    
    .database_button button:hover {
        transition: 0.5s;
        background: rgb(0, 151, 71);
        box-shadow: 0px 5px 5px 0px rgba(0, 148, 69, 0.3);
        transform: scale(1.05)
        
        
    }
    
    .text_database {
        font-size: 30px;
        font-weight: 600;
        font-family: 'Inter', sans-serif;
    }
    
    .text_database2 {
        line-height: 0px;
        font-size: 20px;
        font-family: 'Inter', sans-serif;
    }
    
    .dashboard_stats button {
        width: 100%;
        height: 60px;
        margin-bottom: 20px;
        text-align: left;
        border: none;
        background: rgb(0,61,28);
        border-radius: 10px;
        color: white;
        padding: 10px;
        box-shadow: 0px 5px 5px 0px rgb(0,61,28,0.3);
    }
    
    .text_stat {
        font-size: 30px;
        font-weight: 600;
        font-family: 'Inter', sans-serif;
    }
    
    .text_stat2 {
        line-height: 0px;
        font-size: 20px;
        font-family: 'Inter', sans-serif;
    }
}