.orchardeng_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.orchardeng_content {
    width: 900px;
}

.formname {
    display: flex;
    flex-direction: column;
}

.orchardvideo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 900px;
    height: 510px;
}

.orchardeng_video h1 {
    padding: 10px;
    margin-top: 50px;
}

.formname input {
    border: solid 1px rgb(0,61,28);
    width: 400px;
    border-radius: 30px;
    padding: 15px 0px 15px 40px;
    font-size: 20px;
    margin: 0px;
    font-family: 'Inter', sans-serif;
    color: rgb(26, 26, 26);
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 10px 15px 0px rgba(146, 146, 146, 0.3);
}

.formname_container {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px 50px 0px;
}

.orchardeng_form {
    margin-top: 50px;
    margin-bottom: 100px;
}

.orchardeng_form h2 {
    padding: 10px;
}

.orchard_submit {
    margin-top: 50px;
    width: 200px;
    height: 80px;
    text-align: center;
    border: none;
    background: rgb(0,61,28);
    box-shadow: 0px 10px 15px 0px rgb(0,61,28,0.5);
    border-radius: 10px;
    color: white;
    transition: 0.5s;
    font-size: 30px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    cursor: pointer;
}

.orchard_submit:hover {
    transition: 0.5s;
    background: rgb(0, 151, 71);
    box-shadow: 0px 10px 15px 0px rgba(0, 148, 69, 0.5);
}

.formquestion_container label {
    margin: 30px 0px 0px 10px;
    font-size: 25px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    color: black;
}

.formquestion label {
    font-weight: 400;
    font-size: 20px;

}

.formquestion_container {
    padding-bottom: 20px;
}

.formquestion {
    margin-top: 10px;
}

::placeholder {
    color: rgb(138, 138, 138);
  }

@media screen and (max-width: 1000px) {
    .orchardeng_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    
    .orchardeng_content {
        width: 80%;
    }
    
    .formname {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .orchardeng_video {
        width: 100%;
    }
    
    .orchardvideo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    
    .orchardeng_video h1 {
        padding: 0px;
        margin-top: 30px;
        font-size: 25px;
    }
    
    .formname input {
        border: solid 1px rgb(0,61,28);
        width: 90%;
        border-radius: 30px;
        padding: 10px 0px 10px 20px;
        font-size: 20px;
        margin: 0px;
        font-family: 'Inter', sans-serif;
        color: rgb(26, 26, 26);
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 5px 10px 0px rgba(146, 146, 146, 0.3);
        margin-bottom: 15px;
    }
    
    .formname_container {
        display: flex;
        justify-content: space-between;
        margin: 10px 0px 20px 0px;
        width: 100%;
        flex-wrap: wrap;
    }
    
    .orchardeng_form {
        margin-top: 20px;
        margin-bottom: 100px;
        width: 100%;
    }
    
    .orchardeng_form h2 {
        padding: 10px;
        font-size: 25px;
    }
    
    .orchard_submit {
        margin-top: 25px;
        width: 100%;
        height: 50px;
        text-align: center;
        border: none;
        background: rgb(0,61,28);
        box-shadow: 0px 5px 10px 0px rgb(0,61,28,0.3);
        border-radius: 10px;
        color: white;
        transition: 0.5s;
        font-size: 20px;
        font-weight: 600;
        font-family: 'Inter', sans-serif;
        cursor: pointer;
    }
    
    .orchard_submit:hover {
        transition: 0.5s;
        background: rgb(0, 151, 71);
        box-shadow: 0px 10px 15px 0px rgba(0, 148, 69, 0.5);
    }
    
    .formquestion_container label {
        margin: 0px;
        font-size: 20px;
        font-family: 'Inter', sans-serif;
        font-weight: 600;
        color: black;
    }
    
    .formquestion label {
        font-weight: 400;
        font-size: 20px;
    }

    .form_option input {
        margin-right: 10px;
    }
    
    .formquestion {
        margin-top: 10px;
    }
    
    ::placeholder {
        color: rgb(138, 138, 138);
      }

}

@media screen and (max-width: 400px) {
    .orchardvideo {
        height: 210px;
    } 
}

@media screen and (max-width: 350px) {
    .orchardvideo {
        height: 190px;
    } 
}