* {
    margin: 0;
    padding: 0;
}

.orchard_language-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.orchard_language-content {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    width: 900px;
    margin-bottom: 900px;
}

.orchard_language-buttons {
    display: flex;
    flex-direction: row;
    width: 900px;
    justify-content: space-between;
}

.flag {
    z-index: 1;
    transition: 0.3s;
    margin: 5px 0px;
    width: 160px;
    height: 100px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.flag:hover {
    transition: 0.3s;
    transform: scale(1.10)
}

.english {
    background-image: url(../../Assets/english.png);
    background-position: center;
    background-size: cover;
    box-shadow: 0px 10px 15px 0px rgb(0,27,105,0.5);
}

.tongan {
    background-image: url(../../Assets/tonga.png);
    background-position: center;
    background-size: cover;
    box-shadow: 0px 10px 15px 0px rgb(193,0,0,0.5);
}

.pijiin {
    background-image: url(../../Assets/soloman.png);
    background-position: center;
    background-size: cover;
    box-shadow: 0px 10px 15px 0px rgb(0,61,28,0.5);
}

.thai {
    background-image: url(../../Assets/thai.png);
    background-position: center;
    background-size: cover;
    box-shadow: 0px 10px 15px 0px rgb(165,25,49,0.5);
}

.samoan {
    background-image: url(../../Assets/samoa.png);
    background-position: center;
    background-size: cover;
    box-shadow: 0px 10px 15px 0px rgb(205,0,1,0.5);
}

@media screen and (max-width: 500px) {
    * {
        margin: 0;
        padding: 0;
    }
    
    .orchard_language-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .orchard_language-content {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        width: 90%;
        margin-bottom: 300px;
    }

    .orchard_language-content h2 {
        font-size: 25px;
    }
    
    .orchard_language-buttons {
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    
    .flag {
        z-index: 1;
        transition: 0.3s;
        margin: 10px 0px;
        width: 47%;
        height: 100px;
        border: none;
        border-radius: 10px;
        cursor: pointer;
    }
    
    .flag:hover {
        transition: 0.3s;
        transform: scale(1.10)
    }
    
    .english {
        background-image: url(../../Assets/english.png);
        background-position: center;
        background-size: cover;
        box-shadow: 0px 5px 10px 0px rgb(0,27,105,0.3);
    }
    
    .tongan {
        background-image: url(../../Assets/tonga.png);
        background-position: center;
        background-size: cover;
        box-shadow: 0px 5px 10px 0px rgb(193,0,0,0.3);
    }
    
    .pijiin {
        background-image: url(../../Assets/soloman.png);
        background-position: center;
        background-size: cover;
        box-shadow: 0px 5px 10px 0px rgb(0,61,28,0.3);
    }
    
    .thai {
        background-image: url(../../Assets/thai.png);
        background-position: center;
        background-size: cover;
        box-shadow: 0px 5px 10px 0px rgb(165,25,49,0.3);
    }
    
    .samoan {
        background-image: url(../../Assets/samoa.png);
        background-position: center;
        background-size: cover;
        box-shadow: 0px 5px 10px 0px rgb(205,0,1,0.3);
    }
}
