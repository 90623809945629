.induction_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.induction_header {
    margin: 75px 0px 10px 0px;
    width: 780px;
    background-color: black;
    color: white;
    border-radius: 10px;

}

.induction_orchard {
    margin: 10px;
    background-image: url(../../Assets/database.png);
    background-size: cover;

}

.induction_fields {
    margin: 10px;
    background-image: url(../../Assets/induction.png);
    background-size: cover;
}

.induction_packhouse {
    margin: 10px;
    background-image: url(../../Assets/induction.png);
    background-size: cover;
}

.induction_fruitshop {
    margin: 10px;
    background-image: url(../../Assets/database.png);
    background-size: cover;
}

.back button {
    font-weight: 600;
    height: 50px;
    width: 200px;
    padding: 10px;
    color: white;
    font-size: 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    background-color: rgb(0,61,28);
    margin: 0px 0px 200px 0px;
}

.back button:hover {
    background-color:rgb(0, 163, 76);
    transition: 0.7s;
}

.green {
    
    box-shadow: inset 0 0 0 50vw rgba(21, 255, 0, 0.3); 
    pointer-events: none;
}