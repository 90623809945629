.database_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

select {
    margin: 0px 0px 10px 5px;
}

.database_content {
    width: 900px;
    margin: 50px 0px 200px 50px;
}

.database_content h1 {
    padding-bottom: 15px;
}

.styled-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    font-size: 20px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    width: 900px;
}

.overlaytable {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    font-size: 20px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    width: 800px;
}

.database_search input {
    border: solid 1px rgb(0,61,28);
    width: 400px;
    border-radius: 30px;
    padding: 10px 0px 10px 20px;
    font-size: 17px;
    margin: 0px;
    font-family: 'Inter', sans-serif;
    color: rgb(141, 141, 141);
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 5px 15px 0px rgba(146, 146, 146, 0.2);
}

.styled-table a {
    text-decoration: none;
}

.styled-table th {
    background-color: rgb(0,61,28);
    color: #ffffff;
    text-align: left;
}

.overlaytable th {
    background-color: rgb(0,61,28);
    color: #ffffff;
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.overlaytable th,
.overlaytable td {
    padding: 12px 15px;
}

.styled-table tr {
    border-bottom: 1px solid #dfdfdf;
    background-color: rgb(245, 245, 245);
}

.overlaytable tr {
    border-bottom: 1px solid #dfdfdf;
    background-color: rgb(245, 245, 245);
}

.styled-table tr:nth-of-type(even) {
    background-color: #ffffff;
}

.overlaytable tr:nth-of-type(even) {
    background-color: #ffffff;
}

.styled-table tr:last-of-type {
    border-bottom: 2px solid rgb(0,61,28);
}

.overlaytable tr:last-of-type {
    border-bottom: 2px solid rgb(0,61,28);
}

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
}

.overlay:target {
    visibility: visible;
    opacity: 1;
}
  
.popup {
    margin: 100px auto;
    padding: 50px;
    background: #fff;
    border-radius: 10px;
    width: 900px;
    position: relative;
}
  
.popup h2 {
    margin-top: 0;
    color: rgb(22, 22, 22);
}

  .popup .close {
    position: absolute;
    top: 10px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
}

  .popup .close:hover {
    color: rgba(0, 148, 69, 0.5);
}

.popup .content {
    margin: 0px;
    font-size: 20px;
    max-height: 30%;
    overflow: auto;
}

@media print {      
    .popup {
        margin: 0px;
        padding: 50px 0px;
    }
      
    .popup h2 {
        margin-top: 0;
        color: rgb(22, 22, 22);
    }
    
      .popup .close {
        display: none;
        opacity: 0;
    }

    .overlaytable {
        box-shadow: 0 0 0px rgba(0, 0, 0, 0.15);
        font-size: 20px;
        font-weight: 400;
        font-family: 'Inter', sans-serif;
        width: 800px;
        border: 1px solid;
    }
    
    
    .overlaytable th {
        background-color: rgb(255, 255, 255);
        color: #000000;
        text-align: left;
    }
    
    .overlaytable tr {
        border: 1px solid #000000;
        background-color: rgb(255, 255, 255);
    }
    
    
    .overlaytable tr:nth-of-type(even) {
        background-color: #ffffff;
    }
    
    .overlaytable tr:last-of-type {
        border-bottom: 0px solid rgb(0,61,28);
    }
}

@media screen and (max-width: 500px) {
    .database_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    
    .database_content {
        width: 100%;
        margin: 0px 0px 200px 00px;
    }

    .database_content h1 {
        font-size: 25px;
        padding-left: 5%;
    }
    
    .styled-table {
        border-collapse: collapse;
        margin: 25px 0;
        font-size: 0.9em;
        font-family: sans-serif;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
        font-size: 15px;
        font-weight: 400;
        font-family: 'Inter', sans-serif;
        width: 100%;
    }
    
    .overlaytable {
        border-collapse: collapse;
        margin: 25px 0;
        font-size: 0.9em;
        font-family: sans-serif;
        min-width: 400px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
        font-size: 15px;
        font-weight: 400;
        font-family: 'Inter', sans-serif;
        width: 100%;
    }

    .database_search {
        display: flex;
        justify-content: center;
    }
    
    .database_search input {
        border: solid 1px rgb(0,61,28);
        width: 90%;
        border-radius: 30px;
        padding: 3% 0px 3% 5%;
        font-size: 15px;
        margin: 0px;
        font-family: 'Inter', sans-serif;
        color: rgb(141, 141, 141);
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 5px 15px 0px rgba(146, 146, 146, 0.2);
    }
    
    .styled-table a {
        text-decoration: none;
    }
    
    .styled-table th {
        background-color: rgb(0,61,28);
        color: #ffffff;
        text-align: left;
    }
    
    .overlaytable th {
        background-color: rgb(0,61,28);
        color: #ffffff;
        text-align: left;
    }
    
    .styled-table th,
    .styled-table td {
        padding: 8px 8px;
    }
    
    .overlaytable th,
    .overlaytable td {
        padding: 12px 15px;
    }
    
    .styled-table tr {
        border-bottom: 1px solid #dfdfdf;
        background-color: rgb(245, 245, 245);
    }
    
    .overlaytable tr {
        border-bottom: 1px solid #dfdfdf;
        background-color: rgb(245, 245, 245);
    }
    
    .styled-table tr:nth-of-type(even) {
        background-color: #ffffff;
    }
    
    .overlaytable tr:nth-of-type(even) {
        background-color: #ffffff;
    }
    
    .styled-table tr:last-of-type {
        border-bottom: 2px solid rgb(0,61,28);
    }
    
    .overlaytable tr:last-of-type {
        border-bottom: 2px solid rgb(0,61,28);
    }
    
    .overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.7);
        transition: opacity 500ms;
        visibility: hidden;
        opacity: 0;
        width: 100%;
    }

    .database_overlay {
        width: 100;
    }
    
    .overlay:target {
        visibility: visible;
        opacity: 1;
    }
      
    .popup {
        margin: 100px auto;
        padding: 20px 0px;
        background: #fff;
        border-radius: 0px;
        width: 100%;
        position: relative;
    }
      
    .popup h2 {
        font-size: 20px;
        padding-left: 5%;
        margin-top: 0;
        color: rgb(22, 22, 22);
    }
    
      .popup .close {
        position: absolute;
        top: 10px;
        right: 30px;
        transition: all 200ms;
        font-size: 30px;
        font-weight: bold;
        text-decoration: none;
        color: #333;
    }
    
      .popup .close:hover {
        color: rgba(0, 148, 69, 0.5);
    }
    
    .popup .content {
        margin: 0px;
        font-size: 20px;
        max-height: 30%;
        overflow: auto;
    }
}
