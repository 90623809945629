.navbar_container {
    display: flex;
    justify-content: center;
    background-color: white;
}

.navbar_content {
    width: 900px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar_container img {
    height: 100px;
}


.nav_logo {
    margin: 10px;
    border: none;
    background-color: Transparent;
    font-size: 40px;
    cursor: pointer;
}

.navbar_container2 {
    display: flex;
    align-items: center;
    justify-content: end;
}

.navbar {
    height: 80px;
    display: flex;
    align-items: center;

}

.menu-bars {
    font-size: 40px;
    background: none;
    list-style: none;
    margin: 10px;
}

.menu-close {
    font-size: 40px;
    background: none;
    list-style: none;
    margin: 10px;
}


.nav-menu {
    background-color: white;
    width: 400px;
    height: 100vh;
    display: flex;
    justify-content: start;
    position: fixed;
    top: 0;
    left: -500px;
    transition: 1s;
    transition-timing-function: ease;
    box-shadow:10px 0px 100px;
}

.nav-menu.active {
    left: 0;
    transition: 1s;
    transition-timing-function: ease;
}

.nav-text {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
}

.nav-text a {
    text-decoration: none;
    text-align: center;
    color: rgb(10, 10, 10);
    font-size: 30px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
}

.nav-text a:hover {
    color: rgb(0, 168, 79);
    transition: 0.5s;
}

.nav-menu-items {
    width: 70%;
}

.navbar-toggle {
    background-color: white;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.nav_logout {
    font-size: 30px;
    color: #003D1C;
    cursor: pointer;
    transition: 0.3s;
}

.nav_logout:hover {
    color: rgb(0, 168, 79);
}

@media all and (max-width: 1000px) {
    .navbar_content {
        align-items: center;
        width: 80%;
    }

    .navbar_container img {
        height: 80px;
    }

    .navbar_container {
        display: flex;
        justify-content: center;
        background-color: white;
        width: 100%;
    }
}