.bodilyfluids_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.induction_form button {
    margin: 20px 0px 0px 0px;
    background: rgb(0,61,28);
    color: white;
    border: none;
    font-size: 20px;
    height: 50px;
    width: 100%;
    cursor: pointer;
}

.bodilyfluids_video-wrapper {
    width: 780px;
    height: 500px;
}

.induction_form {
    width: 680px;
    margin: 0px 0px 20px 0px;
    display: flex;
    justify-content: center;
    padding: 50px;
    font-size: 20px;
    text-align: left;
    background-color: rgb(230, 230, 230);
}

.bodilyfluids_form input[type=submit] {
    margin: 20px 0px 0px 0px;
    background: black;
    color: white;
    font-size: 20px;
    height: 50px;
    width: 100%;
    cursor: pointer;
}

@media all and (max-width: 1000px) {
    .bodilyfluids_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
    }
    
    .induction_form button {
        margin: 20px 0px 0px 0px;
        background: rgb(0,61,28);
        color: white;
        border: none;
        font-size: 20px;
        height: 50px;
        width: 100%;
        cursor: pointer;
    }
    
    .bodilyfluids_video-wrapper {
        width: 90%;
        height: 100%;
    }
    
    .induction_form {
        width: 100%;
        margin: 0px 0px 20px 0px;
        display: flex;
        justify-content: center;
        padding: 50px;
        font-size: 20px;
        text-align: left;
        background-color: rgb(230, 230, 230);
    }
    
    .bodilyfluids_form input[type=submit] {
        margin: 20px 0px 0px 0px;
        background: black;
        color: white;
        font-size: 20px;
        height: 50px;
        width: 100%;
        cursor: pointer;
    }
}